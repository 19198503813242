<template>
  <div class="base-foreground" :style="style"></div>
</template>

<script setup lang="ts">
import type { IBaseForegroundProps } from './BaseForeground.types';

const props = defineProps<IBaseForegroundProps>();
const { borderRadius, height, width } = toRefs(props);

const style = computed(() => ({
  '--border-radius': GlobalUtils.Converting.toPx(borderRadius.value),
  '--height': GlobalUtils.Converting.toPx(height.value),
  '--width': width?.value ? GlobalUtils.Converting.toPx(width.value) : '100%',
}));
</script>

<style scoped lang="scss" src="./BaseForeground.scss" />
